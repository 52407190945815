/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useTranslation } from 'react-i18next';
import { useUser } from './UserContext';

const LanguagePicker = (props) => {
  const [visibleSrc, setVisibleSrc] = useState(false);
  const [visibleTgt, setVisibleTgt] = useState(false);
  const { t } = useTranslation();
  const { user } = useUser();

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'Tetun', value: 'tet' },
  ];
  if (user.isPaying) {
    languages.push(
      { label: 'Tok Pisin', value: 'tpi' },
      { label: 'Português', value: 'pt' },
      { label: 'Bahasa Indonesia', value: 'id' },
      { label: 'Arabic', value: 'ar' },
      { label: 'Bengali', value: 'bn' },
      { label: 'German', value: 'de' },
      { label: 'Spanish', value: 'es' },
      { label: 'French', value: 'fr' },
      { label: 'Hebrew', value: 'he' },
      { label: 'Hindi', value: 'hi' },
      { label: 'Italian', value: 'it' },
      { label: 'Japanese', value: 'ja' },
      { label: 'Korean', value: 'ko' },
      { label: 'Nepali', value: 'ne' },
      { label: 'Russian', value: 'ru' },
      { label: 'Thai', value: 'th' },
      { label: 'Vietnamese', value: 'vi' },
      { label: 'Chinese', value: 'zh' }
    );
  }

  const groups = [
    languages
      .filter((language) => !['tet', 'tpi'].includes(language.value))
      .map((language) => language.value),
    ['tet', 'tpi'],
  ];

  const showModalSrc = () => {
    setVisibleSrc(true);
  };
  const hideModalSrc = () => setVisibleSrc(false);

  const showModalTgt = () => {
    setVisibleTgt(true);
  };
  const hideModalTgt = () => setVisibleTgt(false);

  const changeSrc = (e) => {
    const newSrc = e.target.value;
    if (newSrc === props.tgt_lang) {
      props.onLanguageChange(props.tgt_lang, props.src_lang);
    } else if (
      groups.find((group) => group.includes(newSrc)).includes(props.tgt_lang)
    ) {
      props.onLanguageChange(
        newSrc,
        groups.find((group) => !group.includes(newSrc))[0]
      );
    } else {
      props.onLanguageChange(newSrc, props.tgt_lang);
    }
    hideModalSrc();
  };

  const changeTgt = (e) => {
    const newTgt = e.target.value;
    if (newTgt === props.src_lang) {
      props.onLanguageChange(props.tgt_lang, props.src_lang);
    } else if (
      groups.find((group) => group.includes(newTgt)).includes(props.src_lang)
    ) {
      props.onLanguageChange(
        newTgt,
        groups.find((group) => !group.includes(newTgt))[0]
      );
    } else {
      props.onLanguageChange(props.src_lang, newTgt);
    }
    hideModalTgt();
  };

  const invertLanguage = () => {
    props.onLanguageChange(props.tgt_lang, props.src_lang);
  };

  const getLanguageLabel = (value) => {
    const language = languages.find((lang) => lang.value === value);
    if (language) {
      return language.label;
    } else {
      // something is very wrong, just use En > Tet
      props.onLanguageChange('en', 'tet');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Button
        variant="text"
        onClick={showModalSrc}
        sx={{
          height: 30,
          minWidth: 150,
        }}
      >
        {getLanguageLabel(props.src_lang)}
      </Button>
      <IconButton
        color="primary"
        aria-label="swap languages"
        onClick={invertLanguage}
      >
        <SwapHorizIcon />
      </IconButton>
      <Button
        variant="text"
        onClick={showModalTgt}
        sx={{
          height: 30,
          minWidth: 150,
        }}
      >
        {getLanguageLabel(props.tgt_lang)}
      </Button>

      <Dialog open={visibleSrc} onClose={hideModalSrc}>
        <DialogTitle>{t('Translate from')}</DialogTitle>
        <DialogContent>
          <RadioGroup value={props.src_lang} onChange={changeSrc}>
            {languages.map((lang) => (
              <FormControlLabel
                key={lang.value}
                value={lang.value}
                control={<Radio />}
                label={lang.label}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideModalSrc}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={visibleTgt} onClose={hideModalTgt}>
        <DialogTitle>{t('Translate to')}</DialogTitle>
        <DialogContent>
          <RadioGroup value={props.tgt_lang} onChange={changeTgt}>
            {languages.map((lang) => (
              <FormControlLabel
                key={lang.value}
                value={lang.value}
                control={<Radio />}
                label={lang.label}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideModalTgt}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LanguagePicker;
