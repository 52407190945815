import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import './index.css';

import Root from './routes/Dictionary';
import SpellChecker from './routes/SpellChecker';
import About from './routes/About';
import Account from './routes/Account';
import Login from './routes/Login';
import LoginGoogle from './routes/LoginGoogle';
import Logout from './routes/Logout';
import Signup from './Signup';
import EmailVerifyOTP from './routes/EmailVerifyOTP';
import Upgrade from './Upgrade';
import AccountDetails from './routes/AccountDetails';
import PasswordResetVerify from './routes/PasswordResetVerify';
import ForgotPassword from './routes/ForgotPassword';
import ForgotPasswordSuccess from './routes/ForgotPasswordSuccess';
import PaymentSuccess from './routes/PaymentSuccess';
import PaymentCancel from './routes/PaymentCancel';
import ConfirmEmail from './routes/ConfirmEmail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/translator',
    element: <Root />,
  },
  {
    path: '/spell-checker',
    element: <SpellChecker />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/account',
    element: <Account />,
    children: [
      {
        path: 'upgrade',
        element: <Upgrade />,
      },
      {
        path: 'details',
        element: <AccountDetails />,
      },
      {
        path: 'signup',
        element: <Signup />,
      },
      {
        path: 'verify',
        element: <EmailVerifyOTP />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'logingoogle',
        element: <LoginGoogle />,
      },
      {
        path: 'reset-password',
        element: <ForgotPassword />,
      },
      {
        path: 'password-reset-verify/:key',
        element: <PasswordResetVerify />,
      },
      {
        path: 'reset-password-success',
        element: <ForgotPasswordSuccess />,
      },
      {
        path: 'success',
        element: <PaymentSuccess />,
      },
      {
        path: 'cancel',
        element: <PaymentCancel />,
      },
      {
        path: 'confirm-email/:key',
        element: <ConfirmEmail />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  },
]);

export default router;
