import * as React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

import NavigationRow from '../NavigationRow';
import LocaleChanger from '../LocaleChanger';

// create an element that will be used for paragraphs

const AboutParagraph = (props: any) => {
  return (
    <Typography
      variant="body1"
      component="p"
      gutterBottom
      dangerouslySetInnerHTML={{ __html: props.html }}
    />
  );
};

export default function About() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: 'rgb(250,250,250)',
        height: '100%',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          pb: 10,
        }}
      >
        <div className="my-4">
          <Typography variant="h5" component="h1" gutterBottom>
            {t('about_header')}
          </Typography>
          <AboutParagraph html={t('about')} />
          <AboutParagraph html={t('credits')} />
          <AboutParagraph html={t('data_sources')} />
          <AboutParagraph html={t('link_to_page')} />
          <AboutParagraph html={t('tetun_classes')} />
        </div>
        <Alert icon={false}>
          Got 2 min? Please take our{' '}
          <Link
            href="https://forms.gle/tQs3M33xCeNb7Yir5"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            user feedback survey
          </Link>
          !
        </Alert>
        <LocaleChanger />
      </Container>
      <NavigationRow />
    </div>
  );
}
