export default {
  en: {
    translation: {
      enter_text: 'Enter text here',
      how_to_switch: 'How to switch between languages?',
      answer1:
        'Click on one of the language names at the top. You will then see a screen to change the source or target language',
      offline_capability: 'Can the application work offline?',
      answer_offline_capability:
        'The dictionary and spell checker work offline. To translate phrases, you need to be online.',
      missing_words: 'Why are some words missing from the application?',
      answer_missing_words:
        'If you find a word that is missing from the application, please let us know. We will look into it and include it in the next version. Thank you for your help!',
      translation_quality: 'Why is the translation not good?',
      answer_translation_quality:
        'It is important to use correct spelling for the application to translate accurately. If you find any issues, please contact us.',
      contact_us_1:
        'If you have comments or suggestions, please contact us through ',
      contact_us_link: 'the Facebook page tetun.org.',
      contact_us_2: '',
      need_internet_to_translate:
        'To translate sentences, you need to be online. Please check your connection and try again.',
      about_header: 'About us',
      about: `Tetun.org is the only free machine translation service for the Tetun language.
        The <a href="https://play.google.com/store/apps/details?id=org.tetun.tetunorg&gl=tl" target="_blank">Android app</a>,
        <a href="https://apps.apple.com/us/app/tetun.org/id1515892208" target="_blank">iOS app</a> and website together have over 60,000 monthly active users, and growing!`,
      credits: `Tetun.org was entirely set up as a volunteer project, led by <a href="https://www.rapha.dev" target="_blank">Raphaël Merx</a> since 2019, and developed in collaboration with
                <a href="mailto:timorlink@hotmail.com">Katrina Langford (TimorLink)</a> since 2021.
                You can support its development on <a href="https://ko-fi.com/raphaelmerx" target="_blank">ko-fi</a>.`,
      data_sources:
        'Data sources: DIT, INL (Instituto Nacional de Linguística) and ground work. Official Government of Timor-Leste spelling from INL.',
      link_to_page: `For comments and suggestions, contact us through <a href="{page_url}" target="_blank"> our Facebook Page</a>
                or email us at <a href="mailto:{mailto}">info@tetun.org</a>.`,
      tetun_classes: `Timorlink also offers Tetun classes: 
                <a href="{page_url}" target="_blank">learntetun.com</a>`,
      spell_check_help:
        'Enter Tetun text below and get spell checking suggestions, using the official <a href="https://untl.edu.tl/pt/centros/instituto-nacional-de-linguistica" target="_blank" rel="noopener noreferrer">INL</a> format.',
    },
  },
  tet: {
    translation: {
      enter_text: "Hatama testu iha ne'e",
      how_to_switch: 'Oinsá bele troka lian?',
      answer1:
        "Click iha butaun lian ida iha leten. Depois sei bele hili lian ne'ebé Ita-Boot hakarak uza.",
      offline_capability: "Aplikasaun bele la'o offline ka lae?",
      answer_offline_capability:
        "Disionáriu no spell checker la'o offline de'it. Hodi tradús fraze sei presiza online.",
      missing_words: 'Tanba sá liafuan balu la eziste iha aplikasaun?',
      answer_missing_words:
        "Se Ita-Boot hetan liafuan ida ne'ebé seidauk eziste iha aplikasaun, favor fó hatene mai ami. Ami sei buka tuir no inklui liafuan ida ne'e iha versaun tuir mai. Obrigadu ba ajuda!",
      translation_quality: "Tanba sa tradús la di'ak?",
      answer_translation_quality:
        "Importante uza ortografia ne'ebé loos para aplikasaun bele tradús didi'ak. Se Ita-Boot hetan problema karik, favór kontaktu ami.",
      contact_us_1:
        'Se iha komentáriu ka sujestaun ruma, favor kontaktu ami liu husi ',
      contact_us_link: 'Pajina Facebook tetun.org.',
      contact_us_2: '',
      need_internet_to_translate:
        'Hodi tradús fraze aplikasaun tenki online. Favor cek rede no koko fali.',
      Translate: 'Tradús',
      Help: 'Ajuda',
      About: 'Kona-ba',
      'Suggest you search for:': 'Sujere ita buka:',
      Copied: 'Kopia ona',
      Copy: 'Kopia',
      Close: 'Taka',
      'Translate from': 'Tradús hosi lian',
      'Translate to': 'Tradús ba lian',
      about_header: 'Kona-ba tetun.org',
      about: '',
      credits: `Maun Raphaël Merx mak kria website ne'e iha tinan 2018. Iha tinan 2021, <a href='mailto:timorlink@hotmail.com'>Mana Katrina Langford (TimorLink)</a>
      komesa servisu hamutuk ho Maun Rapha atu dezenvolve fali website ne'e.`,
      data_sources:
        'Fonte dadus: DIT, INL (Instituto Nacional de Linguística) no servisu rasik. Ortografia ofisiál husi Estadu Timor-Leste no INL.',
      link_to_page: `Se iha komentáriu ka sujestaun balu, favór kontaktu ami liu husi <a href="{page_url}" target="_blank">
              Pajina Facebook tetun.org</a> ka haruka email ba <a href="mailto:{mailto}">info@tetun.org</a>.`,
      tetun_classes: `Timorlink mós oferese kursu Tetun. Ita bele haree kursu refere iha 
              <a href="{page_url}" target="_blank">learntetun.com</a>.`,
      spell_check_help:
        'Hatama textu Tetun iha ne\'e no hetan sugestaun spell checking, uza formatu ofisiál <a href="https://untl.edu.tl/pt/centros/instituto-nacional-de-linguistica" target="_blank" rel="noopener noreferrer">INL</a>.',
      'Display tetun.org in:': 'Haree tetun.org iha lian:',
    },
  },
};
